import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import KTUtil from "@/assets/js/components/util";
import { LOGIN, LOGOUT, FORGOT } from "@/core/services/store/auth.module";
export default {
  name: "login-1",
  data: function data() {
    return {
      state: "signin",
      isLoading: false,
      errors: [],
      forgotEmail: null,
      oauthx: null,
      forgotSuccess: false,
      form: {
        email: null,
        password: null
      }
    };
  },
  computed: {
    backgroundImage: function backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg";
    }
  },
  mounted: function mounted() {
    var _this$$route, _this$$route$query;

    this.oauthx = (_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : (_this$$route$query = _this$$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.oauthx;

    if (this.oauthx == 'success') {
      this.form.email = this.$route.query.email;
    }
  },
  methods: {
    removeError: function removeError(index) {
      this.errors.splice(index, 1);
    },
    submitForgot: function submitForgot() {
      var _this = this;

      _this.errors = [];
      var forgotButton = _this.$refs["kt_login_forgot_submit"];
      forgotButton.classList.add("spinner", "spinner-light", "spinner-right");
      _this.isLoading = true;
      _this.forgotSuccess = false;

      _this.$store.dispatch(FORGOT, {
        email: this.forgotEmail
      }).then(function () {
        _this.forgotSuccess = true;
      }).catch(function (_ref) {
        var data = _ref.data;
        _this.errors = data.data;
        _this.isLoading = false;
        forgotButton.classList.remove("spinner", "spinner-light", "spinner-right");
      });
    },
    submitLogin: function submitLogin() {
      var _this = this;

      _this.errors = [];

      _this.$store.dispatch(LOGOUT);

      var submitButton = _this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      _this.isLoading = true;

      _this.$store.dispatch(LOGIN, _this.form).then(function () {
        var resolved = _this.$router.resolve({
          name: "dashboard"
        });

        window.location.href = resolved.href;
      }).catch(function (_ref2) {
        var data = _ref2.data;
        _this.errors = data.data;
        _this.isLoading = false;
        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
      });
    },
    showForm: function showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(KTUtil.getById(form_name), "animate__animated animate__backInUp");
    }
  }
};